import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Congratz! You know everything you need to build visualizations with React and
D3. 👏`}</p>
    <p>{`This is the point in tech books where I run off and start building things on my
own. Then I get frustrated, spend hours Googling for answers, and then
remember, "Hey! Maybe I should read the rest of the book!"`}</p>
    <p>{`Reading the rest of the book helps. I'll show you how all this fits together
into a larger project.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      